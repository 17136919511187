const routes = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      hidden: true,
    },
    children: [
      {
        path: '/:pathMatch(.*)',
        redirect: "/404"
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/404.vue"),
      },
      {
        path: "home",
        name: "home",
        hidden: true,
        meta: {
          hidden: true,
        },
        component: () => import("@/layout/index.vue"),
        children: [
          {
            path: "",
            name: "indexPage",
            hidden: false,
            meta: {
              hidden: false,
              title: "首页",
            },
            component: () => import("@/views/home/IndexPage.vue")
          },
          {
            path: "course",
            name: "CoursePage",
            redirect: "/home/course/task",
            meta: {
              hidden: true,
              title: "我的课程",
            },
            children: [
              {
                path: "task",
                name: "task",
                // props: true,
                meta: { hidden: false, title: "场景实训" },
                component: () => import("@/views/course/task/index.vue")
              },
              {
                path: "exam",
                name: "exam",
                meta: { hidden: false, title: "考试成绩" },
                component: () => import("@/views/course/examResult/examResult.vue")
              },
              {
                path: "record",
                name: "record",
                meta: { hidden: false, title: "报告提交" },
                component: () => import("@/views/course/record/record.vue")
              },
              {
                path: "test",
                name: "test",
                meta: { hidden: false, title: "在线考试" },
                component: () => import("@/views/course/test/test.vue")
              },
              {
                path: "selfAssessment",
                name: "selfAssessment",
                meta: { hidden: false, title: "自我考核" },
                component: () => import("@/views/course/selfAssessment/selfAssessment.vue")
              }
            ]
          },
       /*   {
            path: "tools",
            name: "tools",
            redirect: "/home/tools/learnTime",
            meta: {
              hidden: false,
              title: "我的助教",
            },
            children: [
              {
                path: "learnTime",
                name: "learnTime",
                meta: { hidden: false, title: "我的学时" },
                component: () => import("@/views/tools/learnTime/learnTime.vue")
              },
              {
                path: "myTest",
                name: "myTest",
                meta: { hidden: false, title: "我的测试" },
                component: () => import("@/views/tools/myTest/myTest.vue")
              },
              {
                path: "testDetail",
                name: "testDetail",
                meta: { hidden: false, title: "我的测试详情" },
                component: () => import("@/views/tools/myTest/testDetail.vue")
              },
              {
                path: "result",
                name: "result",
                meta: { hidden: false, title: "我的成绩" },
                component: () => import("@/views/tools/result/result.vue")
              },
              {
                path: "resultDetail",
                name: "resultDetail",
                meta: { hidden: false, title: "成绩详情" },
                component: () => import("@/views/tools/resultDetail/resultDetail.vue")
              },
              {
                path: "testResultDetail",
                name: "testResultDetail",
                meta: { hidden: false, title: "试卷详情" },
                component: () => import("@/views/tools/resultDetail/testResultDetail/testResultDetail.vue")
              },
              {
                path: "myRecord",
                name: "myRecord",
                meta: { hidden: false, title: "我的报告" },
                component: () => import("@/views/tools/myRecord/myRecord.vue")
              },
              {
                path: "myResource",
                name: "myResource",
                meta: { hidden: false, title: "我的资源" },
                component: () => import("@/views/tools/myResource/myResource.vue")
              },
              {
                path: "myNotification",
                name: "myNotification",
                meta: { hidden: false, title: "我的通知" },
                component: () => import("@/views/tools/myNotification/myNotification.vue")
              },
              {
                path: "myLog",
                name: "myLog",
                meta: { hidden: false, title: "我的日志" },
                component: () => import("@/views/tools/myLog/myLog.vue")
              },
              {
                path: "myKnowledge",
                name: "myKnowledge",
                meta: { hidden: false, title: "我的知识" },
                component: () => import("@/views/tools/myKnowledge/myKnowledge.vue")
              },
              {
                path: "myOrder",
                name: "myOrder",
                meta: { hidden: false, title: "我的工单" },
                component: () => import("@/views/tools/myOrder/myOrder.vue")
              },
              {
                path: "filePreview",
                name: "filePreview",
                meta: { hidden: false, title: "文件查看" },
                component: () => import("@/views/tools/filePreview/filePreview.vue")
              },
              {
                path: "myQuestion",
                name: "myQuestion",
                meta: { hidden: false, title: "我的提问" },
                component: () => import("@/views/tools/myQuestion/myQuestion.vue")
              },
            ]
          }*/
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/500.vue"),
  },
  {
    path: "/screenServer",
    name: "screenServer",
    component: () => import("@/views/screenServer.vue"),
  }
];

export default routes;
