import axios from "axios";
import router from "@/router"
import {message} from "ant-design-vue";

const service = axios.create({
    // baseURL 需要设置为反向代理前缀，不能设置绝对路径URL
    baseURL: "/api",
    timeout: 30000,
    withCredentials: false,
    // headers: {'X-Custom-Header': 'liwei'},
    // headers: {'Content-Type': 'application/json;charset=UTF-8'},
})
// 添加请求拦截器
service.interceptors.request.use(function (req) {
    service.defaults.headers.get['Content-Type']='application/json;charset=UTF-8'
    service.defaults.headers.post['Content-Type']='application/json;charset=UTF-8';
    return req;
}, function (error) {
    return Promise.reject({ error });
});
//添加响应拦截器
service.interceptors.response.use(response => {
    const res = response.data;
    if (res.code !== 200 || res.status !== 'success') {
        // token 过期
        if (res.code === 401){
            message.error("登录已过期, 请重新登录")
            return router.replace("/login")
        }
        if (res.code === 403) return router.replace("/500")
        if (res.code === 404) return router.replace("/500")
        if (res.code === 500) return router.replace("/500")
        if (res.msg === '等待微信扫描' || res.msg === '扫描成功') return Promise.resolve(res.msg)
        if (res.msg === '没有找到对应用户') return Promise.reject(new Error(response.data.msg || "Error"))
        if (res.msg === '校验失败，登录用户不一致' || res.msg === '没有登录信息') {
            message.error(res.msg + ", 请重新登录")
            return  router.replace("/login")
        }
        // 若后台返回错误值，此处返回对应错误对象，下面 error 就会接收
        message.error(response.data.msg)
        return response.data;
    }
    return response.data;
}, error => {
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                error.message = "请求错误(400)"
                break
            case 401:
                error.message = "未授权,请登录(401)"
                router.replace({path: "/login"}).then(r => {})
                break
            case 403:
                error.message = "拒绝访问(403)"
                break
            case 404:
                router.replace("/500").then(r => {})
                break
            case 405:
                error.message = "请求方法未允许(405)"
                break
            case 408:
                error.message = "请求超时(408)"
                router.replace("/500").then(r => {})
                break
            case 500:
                router.replace("/500").then(r => {})
                break
            default:
                error.message = `连接错误: ${error.message}`
                router.replace("/500").then(r => {})
        }
        return Promise.reject(error);
    } else {
        return router.replace("/500").then(r => {})
    }
});

export default service
