import { defineStore } from "pinia";
import {getStorage, setStorage} from "@/utils/localStore";
import {toReactive} from "@vueuse/core";

export const useUserStore = defineStore("user", {
  state: () => ({
    userInfo: null
  }),
  getters: {
    // double: state => state.count * 2,
    getUserInfo: state => {
      console.log({state})  
      if (state && state.userInfo !== null) {
        return state.userInfo
      }
      return getStorage("userInfo")
    }
  },
  actions: {
    setUserInfo(user) {
      setStorage("userInfo", user)
      this.userInfo = user
    }
  },
});
