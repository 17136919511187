// localStorage的存取 只适用于整个对象的存储
export function getStorage(type) {
    return JSON.parse(localStorage.getItem(type) || null)
}

export function setStorage(type, data) {
    const oldData = getStorage(type)
    localStorage.setItem(type, JSON.stringify(Object.assign({}, oldData, data)))
}

export function removeStorage(type) {
    localStorage.removeItem(type)
}
