import { createApp } from "vue";
import App from "./App.vue";
// 注册路由
import router from "./router";
// 注册ant design vue库
import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import "ant-design-vue/dist/antd.less";
// vue状态管理
import { createPinia } from "pinia";
// 打印
import print from "vue3-print-nb";
import CScrollbar from "c-scrollbar";

import api from "@/utils/request"
import 'swiper/css';


const app = createApp(App);
app.config.globalProperties.$api = api;

app.use(router)
    .use(createPinia())
  .use(print)
  .use(CScrollbar)
  .use(Antd)
  .mount("#app");


