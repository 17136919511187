<template>
  <div id="app">
      <a-config-provider :locale="locale === 'en' ? enUS : zhCN">
          <router-view />
      </a-config-provider>
  </div>
</template>

<script setup>
import enUS from 'ant-design-vue/es/locale/en_US';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {onMounted} from "vue";
import {getStorage, setStorage} from "@/utils/localStore";
import a from "@/assets/common/a.png";
import b from "@/assets/common/b.png";
import c from "@/assets/common/c.png";
import { useUserStore } from "@/store/user";

const userStore = useUserStore()

const locale = "zh_CN"

onMounted(() => {
    
    const storage = getStorage("indexList") || null;
    
    if (!storage || JSON.stringify(storage) === '{}') {
        setStorage("indexList", [
            { id: 0, img: a, name: "个人进度", show: true, allowClose: false },
            { id: 1, img: b, name: "课程进度", show: true, allowClose: false },
            { id: 2, img: c, name: "易错题目", show: true, allowClose: true },
            { id: 3, img: a, name: "高频场景", show: true, allowClose: true },
            { id: 4, img: a, name: "学习排行", show: true, allowClose: true },
            { id: 5, img: b, name: "平台资源", show: true, allowClose: true },
            { id: 6, img: c, name: "学习评估", show: true, allowClose: true }
        ])
    }
    
})

const changeFavicon = (ico) => {
    let favicon = document.querySelector('link[rel="icon"]')
    if (favicon !== null) {
        favicon.href = ico
    } else {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = ico
        document.head.appendChild(favicon)
    }
}

userStore.$subscribe((mutation, state) => {
    changeFavicon(state.userInfo?.webICO ?? 'favicon.ico')
})


</script>
<style lang="less">
#app {
  height: 100vh;
  overflow: hidden;
}
body {
    margin: 0 !important;
}
 .ant-table-tbody > tr.ant-table-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #2D579A20 !important;
}
</style>
