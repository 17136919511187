import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from "vue-router";
import routes from "./router.js";
import {getStorage} from "@/utils/localStore";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const whiteList = ['/login', '/register', '/trial'];

const changeFavicon = (ico) => {
  let favicon = document.querySelector('link[rel="icon"]')
  // console.log('app', favicon)
  if (favicon !== null) {
    favicon.href = ico
  } else {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = ico
    document.head.appendChild(favicon)
  }
}

router.beforeEach((to, from, next) => {

  const userInfo = getStorage("userInfo") || {}
  if (to.fullPath === '/login') {
    document.title="船舶建造智慧实验教学平台"
    changeFavicon('favicon.ico')
  } else if (JSON.stringify(userInfo) !== '{}') {
    document.title = userInfo.webTitle
    changeFavicon(userInfo?.webICO);
  }

  const token = getStorage("TOKEN") ?? {}

  if (JSON.stringify(token) === '{}' && whiteList.indexOf(to.path) === -1) router.replace({path: "/login"}).then(r =>{ }).catch(err => console.log({err}))
  next()
})

export default router;
